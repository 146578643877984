/* eslint-disable */

export const jobCodes = [
  { id: '9301', title: 'President', code: '9301' },
  { id: '9301', title: 'President', code: '9301' },
  { id: '9302', title: 'Vice President', code: '9302' },
  { id: '9303', title: 'Associate Vice President', code: '9303' },
  { id: '9303AU', title: 'Chief Auditor', code: '9303AU' },
  { id: '9304', title: 'Assistant Vice President', code: '9304' },
  { id: '9305', title: 'Chancellor', code: '9305' },
  { id: '9308', title: 'Vice Chancellor', code: '9308' },
  { id: '9309', title: 'Associate Vice Chancellor', code: '9309' },
  { id: '9310', title: 'Assistant Vice Chancellor', code: '9310' },
  { id: '9311', title: 'Dean', code: '9311' },
  { id: '9312', title: 'Associate Dean', code: '9312' },
  { id: '9313', title: 'Assistant Dean', code: '9313' },
  { id: '9314', title: 'University Librarian', code: '9314' },
  { id: '9315', title: 'Exec Dir/Corp Sec Brd Regents', code: '9315' },
  { id: '9316', title: 'General Counsel', code: '9316' },
  { id: '9317', title: 'Chief Information Officer', code: '9317' },
  { id: '9318', title: 'Foundation Pres & Chief Ex Ofc', code: '9318' },
  { id: '9319', title: 'Exec Vice President/Provost', code: '9319' },
  { id: '9321', title: 'Controller', code: '9321' },
  { id: '9322', title: 'Associate Vice Provost', code: '9322' },
  { id: '9323', title: 'Assistant Vice Provost', code: '9323' },
  { id: '9324', title: 'Assistant Provost', code: '9324' },
  { id: '9325', title: 'Associate Provost', code: '9325' },
  { id: '9326', title: 'Provost', code: '9326' },
  { id: '9327', title: 'Senior Vice President', code: '9327' },
  { id: '9327FN', title: 'Sr Vice President Finance/Ops', code: '9327FN' },
  { id: '9328', title: 'Vice Provost', code: '9328' },
  { id: '9329', title: 'Chief of Staff, Ofc of Pres', code: '9329' },
  { id: '9330FD', title: 'U of MN Foundation VP', code: '9330FD' },
  { id: '9332', title: 'Deputy Chief of Staff, Pres', code: '9332' },
  { id: '9337AD', title: 'Athletic Director -Twin Cities', code: '9337AD' },
  { id: '9337MD', title: 'Medical Director', code: '9337MD' },
  { id: '9340FR', title: 'Leader (With Faculty Rank)', code: '9340FR' },
  { id: '9350', title: 'Special Assistant', code: '9350' },
  { id: '9360', title: 'Chair (With Faculty Rank)', code: '9360' },
  { id: '9361', title: 'Head (With Faculty Rank)', code: '9361' },
  { id: '9362', title: 'Director (With Faculty Rank)', code: '9362' },
  { id: '9399', title: 'President Emeritus', code: '9399' },
  { id: '9719', title: 'Optometrist', code: '9719' },
  { id: '9721', title: 'Physician', code: '9721' },
  { id: '9722', title: 'Dentist', code: '9722' },
  { id: '9735', title: 'Senior Attorney', code: '9735' },
  { id: '9751', title: 'Senior Fellow', code: '9751' },
  { id: '9752', title: 'Fellow', code: '9752' },
  { id: '9754C', title: 'Teaching Specialist/Assoc Prof', code: '9754C' },
  { id: '9754CC', title: 'Tchg Spec/Clinical Assoc Prof', code: '9754CC' },
  { id: '9754CR', title: 'Tchg Spec/Rsrch Assoc Prof', code: '9754CR' },
  { id: '9754CS', title: 'Tchg Spec/Svc/Otrch Assoc Prof', code: '9754CS' },
  { id: '9754CT', title: 'Tchg Spec/Tchg Assoc Prof', code: '9754CT' },
  { id: '9754F', title: 'Tchg Specialist/Adjunct Prof', code: '9754F' },
  { id: '9754FC', title: 'Tchg Spec/Adjunct Clncl Prof', code: '9754FC' },
  { id: '9754FR', title: 'Tchg Spec/Adjunct Rsrch Prof', code: '9754FR' },
  { id: '9754FS', title: 'Tchg Spec/Adjnc Svc/Otrch Prof', code: '9754FS' },
  { id: '9754FT', title: 'Tchg Spec/Adjunct Tchg Prof', code: '9754FT' },
  { id: '9754N', title: 'Tchg Spec/Adjunct Asst Prof', code: '9754N' },
  { id: '9754NC', title: 'Tchg Spec/Adjc Clncl Asst Prof', code: '9754NC' },
  { id: '9754NR', title: 'Tchg Spc/Adjnc Rsrch Asst Prof', code: '9754NR' },
  { id: '9754NS', title: 'Tchg Spc/Adjc Svc/Otrc Ast Prf', code: '9754NS' },
  { id: '9754NT', title: 'Tchg Spec/Adjnc Tchg Asst Prof', code: '9754NT' },
  { id: '9754P', title: 'Teaching Specialist/Professor', code: '9754P' },
  { id: '9754PC', title: 'Tchg Specialist/Clinical Prof', code: '9754PC' },
  { id: '9754PR', title: 'Teaching Specialist/Rsrch Prof', code: '9754PR' },
  { id: '9754PS', title: 'Tchg Spec and Svc/Outrch Prof', code: '9754PS' },
  { id: '9754PT', title: 'Teaching Specialist/Tchg Prof', code: '9754PT' },
  { id: '9754S', title: 'Tchg Spec/Adjunct Assoc Prof', code: '9754S' },
  { id: '9754SC', title: 'Tchg Spec/Adjc Clncl Assc Prof', code: '9754SC' },
  { id: '9754SR', title: 'Tchg Spec/Adjc Rsrch Assc Prof', code: '9754SR' },
  { id: '9754SS', title: 'Tchg Spc/Adjc Svc/Otrc Asc Prf', code: '9754SS' },
  { id: '9754ST', title: 'Tchg Spec/Adjc Tchg Assoc Prof', code: '9754ST' },
  { id: '9754T', title: 'Teaching Specialist/Asst Prof', code: '9754T' },
  { id: '9754TA', title: 'Teaching Attorney', code: '9754TA' },
  { id: '9754TC', title: 'Tchg Spec/Clinical Asst Prof', code: '9754TC' },
  { id: '9754TR', title: 'Tchg Spec/Research Asst Prof', code: '9754TR' },
  { id: '9754TS', title: 'Tchg Spec/Svc/Outrch Asst Prof', code: '9754TS' },
  { id: '9754TT', title: 'Tchg Specialist/Tchg Asst Prof', code: '9754TT' },
  { id: '9755', title: 'Research Specialist', code: '9755' },
  { id: '9771C', title: 'Sr Teaching Spec/Assoc Prof', code: '9771C' },
  { id: '9771CC', title: 'Sr Tchg Spec/Clncl Assoc Prof', code: '9771CC' },
  { id: '9771CR', title: 'Sr Tchg Spec/Rsrch Assoc Prof', code: '9771CR' },
  { id: '9771CS', title: 'Sr Tchg Spec/Svc/Otrc Assc Prf', code: '9771CS' },
  { id: '9771CT', title: 'Sr Tchg Spec/Tchg Assoc Prof', code: '9771CT' },
  { id: '9771F', title: 'Sr Tchg Specialist/Adjnc Prof', code: '9771F' },
  { id: '9771FC', title: 'Sr Tchg Spec/Adjnc Clncl Prof', code: '9771FC' },
  { id: '9771FR', title: 'Sr Tchg Spec/Adjnc Rsrch Prof', code: '9771FR' },
  { id: '9771FS', title: 'Sr Tchg Spec/Adjc Svc/Otrc Prf', code: '9771FS' },
  { id: '9771FT', title: 'Sr Tchg Spec/Adjunct Tchg Prof', code: '9771FT' },
  { id: '9771N', title: 'Sr Tchg Spec/Adjunct Asst Prof', code: '9771N' },
  { id: '9771NC', title: 'Sr Tchg Spc/Adjc Clncl Ast Prf', code: '9771NC' },
  { id: '9771NR', title: 'Sr Tchg Spc/Adjc Rsrch Ast Prf', code: '9771NR' },
  { id: '9771NS', title: 'Sr Tc Sp/Adjc Svc/Otrc Ast Prf', code: '9771NS' },
  { id: '9771NT', title: 'Sr Tchg Spec/Adjc Tchg Ast Prf', code: '9771NT' },
  { id: '9771P', title: 'Sr Teaching Specialist/Prof', code: '9771P' },
  { id: '9771PC', title: 'Sr Tchg Specialist/Clncl Prof', code: '9771PC' },
  { id: '9771PR', title: 'Sr Tchg Specialist/Rsrch Prof', code: '9771PR' },
  { id: '9771PS', title: 'Sr Tchg Spec/Svc/Outrch Prof', code: '9771PS' },
  { id: '9771PT', title: 'Sr Tchg Specialist/Tchg Prof', code: '9771PT' },
  { id: '9771S', title: 'Sr Tchg Spec/Adjnc Assoc Prof', code: '9771S' },
  { id: '9771SC', title: 'Sr Tchg Spc/Adjc Clncl Asc Prf', code: '9771SC' },
  { id: '9771SR', title: 'Sr Tchg Spc/Adjc Rsrch Asc Prf', code: '9771SR' },
  { id: '9771SS', title: 'Sr Tc Sp/Adjc Svc/Otrc Asc Prf', code: '9771SS' },
  { id: '9771ST', title: 'Sr Tchg Spc/Adjnc Tchg Asc Prf', code: '9771ST' },
  { id: '9771T', title: 'Sr Tchg Specialist/Asst Prof', code: '9771T' },
  { id: '9771TA', title: 'Senior Teaching Attorney', code: '9771TA' },
  { id: '9771TC', title: 'Sr Tchg Spec/Clncl Asst Prof', code: '9771TC' },
  { id: '9771TR', title: 'Sr Tchg Spec/Rsrch Asst Prof', code: '9771TR' },
  { id: '9771TS', title: 'Sr Tchg Spec/Svc/Otrc Asst Prf', code: '9771TS' },
  { id: '9771TT', title: 'Sr Tchg Spec/Tchg Asst Prof', code: '9771TT' },
  { id: '9753C', title: 'Lecturer/Associate Professor', code: '9753C' },
  { id: '9753CC', title: 'Lecturer/Clinical Assoc Prof', code: '9753CC' },
  { id: '9753CR', title: 'Lecturer/Rsrch Associate Prof', code: '9753CR' },
  { id: '9753CS', title: 'Lecturer/Svc/Outrch Assoc Prof', code: '9753CS' },
  { id: '9753CT', title: 'Lecturer/Tchg Associate Prof', code: '9753CT' },
  { id: '9753F', title: 'Lecturer/Adjunct Professor', code: '9753F' },
  { id: '9753FC', title: 'Lecturer/Adjunct Clinical Prof', code: '9753FC' },
  { id: '9753FR', title: 'Lecturer/Adjunct Rsrch Prof', code: '9753FR' },
  { id: '9753FS', title: 'Lecturer/Adjnc Svc/Outrch Prof', code: '9753FS' },
  { id: '9753FT', title: 'Lecturer/Adjunct Tchg Prof', code: '9753FT' },
  { id: '9753N', title: 'Lecturer/Adjunct Asst Prof', code: '9753N' },
  { id: '9753NC', title: 'Lecturer/Adjnc Clncl Asst Prof', code: '9753NC' },
  { id: '9753NR', title: 'Lecturer/Adjnc Rsrch Asst Prof', code: '9753NR' },
  { id: '9753NS', title: 'Lectr/Adjc Svc/Otrch Asst Prof', code: '9753NS' },
  { id: '9753NT', title: 'Lecturer/Adjnc Tchg Asst Prof', code: '9753NT' },
  { id: '9753P', title: 'Lecturer/Professor', code: '9753P' },
  { id: '9753PC', title: 'Lecturer/Clinical Professor', code: '9753PC' },
  { id: '9753PR', title: 'Lecturer/Research Professor', code: '9753PR' },
  { id: '9753PS', title: 'Lecturer and Svc/Outreach Prof', code: '9753PS' },
  { id: '9753PT', title: 'Lecturer/Teaching Professor', code: '9753PT' },
  { id: '9753S', title: 'Lecturer/Adjunct Assoc Prof', code: '9753S' },
  { id: '9753SC', title: 'Lecturer/Adjc Clncl Assoc Prof', code: '9753SC' },
  { id: '9753SR', title: 'Lecturer/Adjc Rsrch Assoc Prof', code: '9753SR' },
  { id: '9753SS', title: 'Lectr/Adjc Svc/Otrc Assoc Prof', code: '9753SS' },
  { id: '9753ST', title: 'Lecturer/Adjnc Tchg Assoc Prof', code: '9753ST' },
  { id: '9753T', title: 'Lecturer/Assistant Professor', code: '9753T' },
  { id: '9753TC', title: 'Lecturer/Clinical Asst Prof', code: '9753TC' },
  { id: '9753TR', title: 'Lecturer/Research Asst Prof', code: '9753TR' },
  { id: '9753TS', title: 'Lecturer/Svc/Outrch Asst Prof', code: '9753TS' },
  { id: '9753TT', title: 'Lecturer/Teaching Asst Prof', code: '9753TT' },
  { id: '9770C', title: 'Sr Lecturer/Associate Prof', code: '9770C' },
  { id: '9770CC', title: 'Sr Lecturer/Clncl Assoc Prof', code: '9770CC' },
  { id: '9770CR', title: 'Sr Lecturer/Rsrch Assoc Prof', code: '9770CR' },
  { id: '9770CS', title: 'Sr Lectr/Svc/Outrch Assoc Prof', code: '9770CS' },
  { id: '9770CT', title: 'Sr Lecturer/Tchg Assoc Prof', code: '9770CT' },
  { id: '9770F', title: 'Sr Lecturer/Adjunct Professor', code: '9770F' },
  { id: '9770FC', title: 'Sr Lecturer/Adjunct Clncl Prof', code: '9770FC' },
  { id: '9770FR', title: 'Sr Lecturer/Adjunct Rsrch Prof', code: '9770FR' },
  { id: '9770FS', title: 'Sr Lectr/Adjnc Svc/Outrch Prof', code: '9770FS' },
  { id: '9770FT', title: 'Sr Lecturer/Adjunct Tchg Prof', code: '9770FT' },
  { id: '9770N', title: 'Sr Lecturer/Adjunct Asst Prof', code: '9770N' },
  { id: '9770NC', title: 'Sr Lectr/Adjnc Clncl Asst Prof', code: '9770NC' },
  { id: '9770NR', title: 'Sr Lectr/Adjnc Rsrch Asst Prof', code: '9770NR' },
  { id: '9770NS', title: 'Sr Lectr/Adjc Svc/Otrc Ast Prf', code: '9770NS' },
  { id: '9770NT', title: 'Sr Lectr/Adjnc Tchg Asst Prof', code: '9770NT' },
  { id: '9770P', title: 'Senior Lecturer/Professor', code: '9770P' },
  { id: '9770PC', title: 'Sr Lecturer/Clinical Professor', code: '9770PC' },
  { id: '9770PR', title: 'Sr Lecturer/Research Professor', code: '9770PR' },
  { id: '9770PS', title: 'Sr Lecturer and Svc/Otrch Prof', code: '9770PS' },
  { id: '9770PT', title: 'Sr Lecturer/Teaching Professor', code: '9770PT' },
  { id: '9770S', title: 'Sr Lecturer/Adjunct Assoc Prof', code: '9770S' },
  { id: '9770SC', title: 'Sr Lectr/Adjc Clncl Assoc Prof', code: '9770SC' },
  { id: '9770SR', title: 'Sr Lectr/Adjc Rsrch Assoc Prof', code: '9770SR' },
  { id: '9770SS', title: 'Sr Lectr/Adjc Svc/Otrc Asc Prf', code: '9770SS' },
  { id: '9770ST', title: 'Sr Lectr/Adjnc Tchg Assoc Prof', code: '9770ST' },
  { id: '9770T', title: 'Sr Lecturer/Assistant Prof', code: '9770T' },
  { id: '9770TC', title: 'Sr Lecturer/Clinical Asst Prof', code: '9770TC' },
  { id: '9770TR', title: 'Sr Lecturer/Research Asst Prof', code: '9770TR' },
  { id: '9770TS', title: 'Sr Lectr/Svc/Outrch Asst Prof', code: '9770TS' },
  { id: '9770TT', title: 'Sr Lecturer/Teaching Asst Prof', code: '9770TT' },
  { id: '9404', title: 'Instructor', code: '9404' },
  { id: '9404A', title: 'Adjunct Instructor', code: '9404A' },
  { id: '9404AC', title: 'Adjunct Clinical Instructor', code: '9404AC' },
  { id: '9404AR', title: 'Adjunct Research Instructor', code: '9404AR' },
  { id: '9404AS', title: 'Adjunct Service/Outreach Instr', code: '9404AS' },
  { id: '9404AT', title: 'Adjunct Teaching Instructor', code: '9404AT' },
  { id: '9404C', title: 'Clinical Instructor', code: '9404C' },
  { id: '9404CR', title: 'Clinical Research Instructor', code: '9404CR' },
  { id: '9404CS', title: 'Clinical Svc/Outreach Instr', code: '9404CS' },
  { id: '9404CT', title: 'Clinical Teaching Instructor', code: '9404CT' },
  { id: '9404R', title: 'Research Instructor', code: '9404R' },
  { id: '9404S', title: 'Service/Outreach Instructor', code: '9404S' },
  { id: '9404T', title: 'Teaching Instructor', code: '9404T' },
  { id: '9404V', title: 'Visiting Instructor', code: '9404V' },
  { id: '9404VC', title: 'Visiting Clinical Instructor', code: '9404VC' },
  { id: '9404VR', title: 'Visiting Research Instructor', code: '9404VR' },
  { id: '9404VS', title: 'Visiting Svc/Outrch Instructor', code: '9404VS' },
  { id: '9404VT', title: 'Visiting Teaching Instructor', code: '9404VT' },
  { id: '9401', title: 'Professor', code: '9401' },
  { id: '9401A', title: 'Adjunct Professor', code: '9401A' },
  { id: '9401AC', title: 'Adjunct Clinical Professor', code: '9401AC' },
  { id: '9401AR', title: 'Adjunct Research Professor', code: '9401AR' },
  { id: '9401AS', title: 'Adjunct Svc/Outreach Professor', code: '9401AS' },
  { id: '9401AT', title: 'Adjunct Teaching Professor', code: '9401AT' },
  { id: '9401C', title: 'Clinical Professor', code: '9401C' },
  { id: '9401CR', title: 'Clinical Research Professor', code: '9401CR' },
  { id: '9401CS', title: 'Clinical Svc/Outrch Professor', code: '9401CS' },
  { id: '9401CT', title: 'Clinical Teaching Professor', code: '9401CT' },
  { id: '9401R', title: 'Research Professor', code: '9401R' },
  { id: '9401S', title: 'Service/Outreach Professor', code: '9401S' },
  { id: '9401T', title: 'Teaching Professor', code: '9401T' },
  { id: '9401V', title: 'Visiting Professor', code: '9401V' },
  { id: '9401VC', title: 'Visiting Clinical Professor', code: '9401VC' },
  { id: '9401VR', title: 'Visiting Research Professor', code: '9401VR' },
  { id: '9401VS', title: 'Visiting Service/Outreach Prof', code: '9401VS' },
  { id: '9401VT', title: 'Visiting Teaching Professor', code: '9401VT' },
  { id: '9402', title: 'Associate Professor', code: '9402' },
  { id: '9402A', title: 'Adjunct Associate Professor', code: '9402A' },
  { id: '9402AC', title: 'Adjunct Clinical Assoc Prof', code: '9402AC' },
  { id: '9402AR', title: 'Adjunct Research Assoc Prof', code: '9402AR' },
  { id: '9402AS', title: 'Adjunct Svc/Outrch Assoc Prof', code: '9402AS' },
  { id: '9402AT', title: 'Adjunct Teaching Assoc Prof', code: '9402AT' },
  { id: '9402C', title: 'Clinical Associate Professor', code: '9402C' },
  { id: '9402CR', title: 'Clinical Research Assoc Prof', code: '9402CR' },
  { id: '9402CS', title: 'Clinical Svc/Outrch Assoc Prof', code: '9402CS' },
  { id: '9402CT', title: 'Clinical Teaching Assoc Prof', code: '9402CT' },
  { id: '9402R', title: 'Research Associate Professor', code: '9402R' },
  { id: '9402S', title: 'Svc/Outreach Assoc Professor', code: '9402S' },
  { id: '9402T', title: 'Teaching Associate Professor', code: '9402T' },
  { id: '9402V', title: 'Visiting Associate Professor', code: '9402V' },
  { id: '9402VC', title: 'Visiting Clinical Assoc Prof', code: '9402VC' },
  { id: '9402VR', title: 'Visiting Research Assoc Prof', code: '9402VR' },
  { id: '9402VS', title: 'Visiting Svc/Outrch Assoc Prof', code: '9402VS' },
  { id: '9402VT', title: 'Visiting Teaching Assoc Prof', code: '9402VT' },
  { id: '9403', title: 'Assistant Professor', code: '9403' },
  { id: '9403A', title: 'Adjunct Assistant Professor', code: '9403A' },
  { id: '9403AC', title: 'Adjunct Clinical Asst Prof', code: '9403AC' },
  { id: '9403AR', title: 'Adjunct Research Asst Prof', code: '9403AR' },
  { id: '9403AS', title: 'Adjunct Svc/Outrch Asst Prof', code: '9403AS' },
  { id: '9403AT', title: 'Adjunct Teaching Asst Prof', code: '9403AT' },
  { id: '9403C', title: 'Clinical Assistant Professor', code: '9403C' },
  { id: '9403CR', title: 'Clinical Research Asst Prof', code: '9403CR' },
  { id: '9403CS', title: 'Clinical Svc/Outrch Asst Prof', code: '9403CS' },
  { id: '9403CT', title: 'Clinical Teaching Asst Prof', code: '9403CT' },
  { id: '9403R', title: 'Research Assistant Professor', code: '9403R' },
  { id: '9403S', title: 'Svc/Outreach Asst Professor', code: '9403S' },
  { id: '9403T', title: 'Teaching Assistant Professor', code: '9403T' },
  { id: '9403V', title: 'Visiting Assistant Professor', code: '9403V' },
  { id: '9403VC', title: 'Visiting Clinical Asst Prof', code: '9403VC' },
  { id: '9403VR', title: 'Visiting Research Asst Prof', code: '9403VR' },
  { id: '9403VS', title: 'Visit Svc/Outreach Asst Prof', code: '9403VS' },
  { id: '9403VT', title: 'Visiting Teaching Asst Prof', code: '9403VT' },
  { id: '9410', title: 'Regents Professor', code: '9410' },
  { id: '9535', title: 'Professional Program Assistant', code: '9535' },
  { id: '9552CR', title: 'Chief Dental Fellow Specialist', code: '9552CR' },
  { id: '9553', title: 'Dental Fellow', code: '9553' },
  { id: '9553CR', title: 'Chief Dental Fellow', code: '9553CR' },
  { id: '9540', title: 'Pharmacy Associate', code: '9540' },
  { id: '9545', title: 'Pre-Doctoral Assistant', code: '9545' },
  { id: '9546', title: 'Post-Doctoral Associate', code: '9546' },
];
