/* eslint-disable */
import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,
  Avatar,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useState, useEffect } from 'react';
import db from '../../../firebaseDb';
import useAuth from '../../../hooks/useAuth';
import toast from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// When this component is rendered, the user has already signed in since it is under the AuthGuard.

const RequestAccessForm = () => {
  const { user, logout } = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState(user.email);
  const [affiliation, setAffiliation] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // db.collection('users')
    //   .doc(user.id)
    //   .set(
    //     {
    //       accessPending: true,
    //       accessRequestDetails: {
    //         name: name,
    //         email: email, // Don't allow free entry, since we append the request to the signed-in user ID.
    //         affiliation: affiliation,
    //       },
    //     },
    //     { merge: true }
    //   );
      db.collection('accessRequests')
      .doc().set(
        {
          userID: user.id,
          name: name,
          email: email,
          affiliation: affiliation,
          resolved: false,
        },
        { merge: true }
      );
    // handleNewAccessRequest 
    setSubmitted(true);
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/faculty/dashboard');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1 }}
                variant="subtitle2"
              >
                Name
              </Typography>
              <TextField
                fullWidth
                name="name"
                onchange
                required
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1 }}
                variant="subtitle2"
              >
                Email Address
              </Typography>
              <TextField
                fullWidth
                name="email"
                type="email"
                required
                variant="outlined"
                value={email}
                error={emailError}
                helperText={
                  emailError ? (
                    <Typography>
                      You cannot change your email. To request access for
                      another email please sign in with that email.{' '}
                      <Link onClick={handleLogout}>Logout</Link>
                    </Typography>
                  ) : (
                    ''
                  )
                }
                // helperText={<Link>Test</Link>}

                onChange={(e) => setEmailError(true)}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography
                color="textPrimary"
                sx={{ mb: 1 }}
                variant="subtitle2"
              >
                Affiliation
              </Typography>
              <TextField
                fullWidth
                name="affiliation"
                required
                variant="outlined"
                value={affiliation}
                onChange={(e) => setAffiliation(e.target.value)}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              type="submit"
              color="primary"
              // fullWidth
              width="90%"
              size="large"
              variant="contained"
            >
              Request Access
            </Button>
          </Box>
        </form>
      ) : (
        <div width="100%" align="center" maxHeight="100%">
          <Card sx={{ mx: 0, width: '50%' }}>
            <CardContent>
              <Box
                sx={{
                  maxWidth: 450,
                  mx: 'auto',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: 'primary.main',
                      color: 'primary.contrastText',
                    }}
                  >
                    <CheckCircleIcon fontSize="small" />
                  </Avatar>
                </Box>
                <Box sx={{ mt: 2, mb: 4 }}>
                  <Typography align="center" color="textPrimary" variant="h3">
                    Request Submitted!
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                  }}
                >
                  <Typography align="center" color="primary" variant="subtitle">
                    We will be in touch soon.
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default RequestAccessForm;
