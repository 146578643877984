/* eslint-disable */
import { useEffect } from 'react';
import { Link as BrowserLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Container,
  Link,
  Typography,
  Card,
  CardMedia,
  CardActions,
  CardHeader,
  Divider,
  Button,
} from '@material-ui/core';
import { ContactForm } from '../../components/contact';
import RequestAccessForm from '../../components/dashboard/faculty/RequestAccessForm';
import Logo from '../../components/Logo';
import MailIcon from '../../icons/Mail';
import gtm from '../../lib/gtm';
import noAccess from '../../components/noAccess.svg';
const RequestAccess = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Request Access | GopheResearch</title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            lg: '60% 40%', //repeat(2, 1fr)
            xs: 'repeat(1, 1fr)',
          },
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            pt: 8,
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              // maxHeight: '100vh',
              // pl: {
              //   lg: 15
              // }
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ fontWeight: 'fontWeightBold', textAlign: 'center' }}
              variant="h1"
            >
              You don't have access to the faculty portal.
            </Typography>
            <img src={noAccess} />

          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            pt: 8,
          }}
        >
          <Container maxWidth="md"
           >
            <Box sx={{
              // height: '100vh',
            }}
            >
              <RequestAccessForm />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
















  );
};

export default RequestAccess;
