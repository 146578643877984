/* eslint-disable */
//  For Analytics Purposes Only //
// User is always authenticated before this component is called //
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import db from '../firebaseDb';
import firebase from 'firebase';

const StudentGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    try {
      // Log Student Login to Analytics
      db.collection('analytics').doc().set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userID: user.id,
        type: 'studentLogin',
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

StudentGuard.propTypes = {
  children: PropTypes.node,
};

export default StudentGuard;
