/* eslint-disable */

{
  /* USAGE
    
    import { employeeInformationApi } from '../../api/employeeInformationApi';

    Sample function in foreign file:

    function callApi() {
        setLoading(true);
        employeeInformationApi(query).then((response) => {
        setResponse(response.data);
        setLoading(false);
        setResponseStatus('Status Code: ' + response.status);
    });
  }
    
    The function (employeeInformationApi) defiend in this file returns the reponse and the status code both in an object.
    
    */
}

export async function employeeInformationApi(internetId) {
  var response = '';
  var responseStatus = '';
  let base64 = require('base-64');
  let url =
    'https://integration-boomi.umn.edu/prd/ws/rest/employeeinformation/JobPositionInformation/' +
    internetId;
  let username = 'GopheResearch';
  let password = 'wzXDnb9G56LPUBb2';
  let APIKEY = '356a854d-b7b8-4941-899d-234d974b52d9';

  let headers = new Headers();

  headers.set(
    'authorization',
    'Basic ' + base64.encode(username + ':' + password)
  );
  headers.append('X-API-Key', APIKEY);
  headers.append('accept', 'application/json');

  await fetch(url, { headers: headers })
    .then(function (response) {
      responseStatus = response.status;
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong');
    })
    .then((data) => {
      response = data[0];
    });

  return {
    data: response,
    status: responseStatus,
  };
}
