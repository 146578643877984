import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
// import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Dashboard from '@material-ui/icons/Dashboard';
import ManageSearchOutlined from '@material-ui/icons/ManageSearchOutlined';
import StarOutlined from '@material-ui/icons/StarOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import db from '../../firebaseDb';
/* eslint-disable */

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Guide',
        path: '/dashboard',
        icon: <Dashboard fontSize="small" />,
      },
      // {
      //   title: 'Analytics',
      //   path: '/dashboard/analytics',
      //   icon: <ChartPieIcon fontSize="small" />
      // },
      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />
      // },
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  // {
  //   title: 'Management',
  //   items: [
  //     {
  //       title: 'Customers',
  //       path: '/dashboard/customers',
  //       icon: <UsersIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/customers'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/customers/1'
  //         },
  //         {
  //           title: 'Edit',
  //           path: '/dashboard/customers/1/edit'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Products',
  //       path: '/dashboard/products',
  //       icon: <ShoppingCartIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/products'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/products/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: <FolderOpenIcon fontSize="small" />,
  //       path: '/dashboard/orders',
  //       children: [
  //         {
  //           title: 'List',
  //           path: '/dashboard/orders'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/orders/1'
  //         }
  //       ]
  //     },
  //     // {
  //     //   title: 'Invoices',
  //     //   path: '/dashboard/invoices',
  //     //   icon: <ReceiptIcon fontSize="small" />,
  //     //   children: [
  //     //     {
  //     //       title: 'List',
  //     //       path: '/dashboard/invoices'
  //     //     },
  //     //     {
  //     //       title: 'Details',
  //     //       path: '/dashboard/invoices/1'
  //     //     }
  //     //   ]
  //     // }
  //   ]
  // },
  {
    title: 'Opportunities',
    items: [
      {
        title: 'Browse',
        path: '/dashboard/projects/browse',
        icon: <ManageSearchOutlined fontSize="small" />,
        // children: [
        //   {
        //     title: 'Browse',
        //     path: '/dashboard/projects/browse'
        //   },
        //   {
        //     title: 'Details',
        //     path: '/dashboard/projects/1'
        //   },
        //   // {
        //   //   title: 'Create',
        //   //   path: '/dashboard/projects/new'
        //   // }
        // ]
      },
      {
        title: 'Favourites',
        path: '/dashboard/favourites',
        icon: <StarOutlined fontSize="small" />,
      },
      // {
      //   title: 'Social',
      //   path: '/dashboard/social',
      //   icon: <ShareIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'Profile',
      //       path: '/dashboard/social/profile'
      //     },
      //     {
      //       title: 'Feed',
      //       path: '/dashboard/social/feed'
      //     }
      //   ]
      // }
    ],
  },
  {
    title: 'Applications',
    items: [
      {
        title: 'Manage',
        path: '/dashboard/applications',
        icon: <AssignmentOutlined fontSize="small" />,
        // children: [
        //   {
        //     title: 'Browse',
        //     path: '/dashboard/projects/browse'
        //   },
        //   {
        //     title: 'Details',
        //     path: '/dashboard/projects/1'
        //   },
        //   // {
        //   //   title: 'Create',
        //   //   path: '/dashboard/projects/new'
        //   // }
        // ]
      },
    ],
  },
  // {
  //   title: 'Apps',
  //   items: [
  //     {
  //       title: 'Kanban',
  //       path: '/dashboard/kanban',
  //       icon: <ClipboardListIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Mail',
  //       path: '/dashboard/mail',
  //       icon: <MailIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Chat',
  //       path: '/dashboard/chat',
  //       icon: <ChatAltIcon fontSize="small" />
  //     },
  //     {
  //       title: 'Calendar',
  //       path: '/dashboard/calendar',
  //       icon: <CalendarIcon fontSize="small" />
  //     }
  //   ]
  // }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [userData, setUserData] = useState('');
  const [result, setResult] = useState(false);

  useEffect(() => {
    db.collection('users')
      .doc(user.id)
      .onSnapshot((doc) => {
        setUserData(doc.data());
        if (doc.exists) {
          setResult(true);
        }
      });
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {/* {userData.name} */}
                {result ? userData.name : 'User Not Found'}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/dashboard/account"
                >
                  {user.email}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        {/* // bottom:0, width:'100%', position:'sticky', backgroundColor:'#ffcc33' */}
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {/* Need Help? */}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {/* Check our docs   */}
          </Typography>
          <Button
            color="primary"
            // component={RouterLink}
            component={Link}
            fullWidth
            sx={{ mt: 0 }}
            // to="/contact"
            onClick={() =>
              window.open('https://gopheresearch.umn.edu/contact', '_blank')
            }
            variant="contained"
          >
            Contact
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
