/* eslint-disable */
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import NoAdminAccess from '../pages/admin/NoAdminAccess';
import db from '../firebaseDb';
import { Box, CircularProgress } from '@material-ui/core';

const FacultyGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    db.collection('users')
      .doc(user.id)
      .get()
      .then((doc) => {
        try {
          if (doc.data().admin == true) {
            setIsAdmin(true);
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      });
  }, []);

  if (!isAdmin) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <>
        {loading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <NoAdminAccess />
        )}
      </>
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

FacultyGuard.propTypes = {
  children: PropTypes.node,
};

export default FacultyGuard;
