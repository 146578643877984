// import firebase from 'firebase';
// import { firebaseConfig } from './config';

// const firebaseApp = firebase.initializeApp(firebaseConfig);

// const db = firebaseApp.firestore();

// export default db;
import '@firebase/firestore';

import firebase from './lib/firebase';

const db = firebase.firestore();

export default db;
