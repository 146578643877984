/* eslint-disable */
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import RequestAccess from '../pages/faculty/RequestAccess';
import db from '../firebaseDb';
import { Box, CircularProgress } from '@material-ui/core';
import firebase from 'firebase';
import { employeeInformationApi } from '../api/employeeInformationApi';
import { jobCodes } from '../components/dashboard/admin/jobCodes';

const FacultyGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const [isFaculty, setIsFaculty] = useState(false);
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    db.collection('users')
      .doc(user.id)
      .get()
      .then((doc) => {
        try {
          // Do both  types of validation since access requests will set the userrole to faculty.
          if (doc.data().userRole == 'faculty') {
            setIsFaculty(true);
          }
          setLoading(false);

          // ------------------------------- NEW API ------------------------------- //
          var internetId = doc.data().email.split('@')[0];

          employeeInformationApi(internetId).then((response) => {
            const approvedJobCodes = jobCodes.map(function (item) {
              return item.code;
            });

            if (approvedJobCodes.includes(response.data.jobCode)) {
              console.log('approved');
              setIsFaculty(true);
            }
            setLoading(false);
          });
          // ----------------------------------------------------------------------- //

          // Log Faculty Login to Analytics
          db.collection('analytics').doc().set({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            userID: user.id,
            type: 'facultyLogin',
          });
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      });
  }, []);

  if (!isFaculty) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <>
        {loading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <RequestAccess />
        )}
      </>
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

FacultyGuard.propTypes = {
  children: PropTypes.node,
};

export default FacultyGuard;
