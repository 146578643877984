export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyArvyD7hnt_UbvC7HRogNYlX3myfuPWuT4',
  appId: '1:850708512473:web:94a09f9c12688656f208c8',
  authDomain: 'gopheresearchapp.firebaseapp.com',
  databaseURL: 'https://gopheresearchapp-default-rtdb.firebaseio.com',
  messagingSenderId: '850708512473',
  projectId: 'gopheresearchapp',
  storageBucket: 'gopheresearchapp.appspot.com',
  measurementId: 'G-JP9MG460ED',
};

export const gtmConfig = {
  containerId: 'GTM-WWLMQMD',
};
