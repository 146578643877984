/* eslint-disable */
import { ReactComponent as LogoUMN } from './LogoUMN.svg';
import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Logo = (props) => (
  <LogoRoot
    height="52"
    viewBox="0 0 52 52" // viewBox="minX minY width height"
    width="52"
    {...props}
  >
    <title>GopheResearch</title>
    <LogoUMN />
  </LogoRoot>
  
);

export default Logo;
