/* eslint-disable */
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import db from '../../../firebaseDb';
import LoginIcon from '@material-ui/icons/Login';

// Whenever someone logs in, we create a user doc for them. This is not ideal
// and is also done with the RegisterFirebase component. Since we will have them sign in directly to "gopheresearch" we have to do this to make life easier and you dont have to indivudally sign up.
// Only set up for google sign in since it much easier.
const LoginFirebase = (props) => {
  const mounted = useMounted();
  const { signInWithEmailAndPassword, signInWithGoogle } = useAuth();

  const handleGoogleClick = async () => {
    try {
      // await signInWithGoogle();

      await signInWithGoogle().then((cred) => {
        console.clear();
        // console.log('cred', cred);

        const userDocRef = db.collection('users').doc(cred.user.uid);

        userDocRef.get().then((doc) => {
          if (doc.exists) {
            // Do Nothing
          } else {
            // This is a new user, create a document for them.
            // Delegated users may or may have not logged in and cloud functions take care of that.
            return db.collection('users').doc(cred.user.uid).set({
              name: cred.user.displayName,
              email: cred.user.email,
            });
          }
        });
      });

      console.log('Successp');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div {...props}>
      <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        // sx={{
        //   backgroundColor: 'common.white',
        //   color: 'common.black',
        //   '&:hover': {
        //     backgroundColor: 'common.white',
        //     color: 'common.black',
        //   },
        // }}
        variant="contained"
        endIcon={<LoginIcon />}
      >
        {/* <Box
          alt="Google"
          component="img"
          src="/static/icons/google.svg"
          sx={{ mr: 1 }}
        /> */}
        Login
      </Button>

      <Box sx={{ mt: 2 }}>
        <Alert severity="info">
          <div>Click the button above and sign in with your UMN account.</div>
        </Alert>
      </Box>

      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mt: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
        
        
        <Typography
          color="textSecondary"
          sx={{ m: 2 }}
          variant="body1"
        >
          OR
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
      </Box> */}

      {/* <Formik
        initialValues={{
          email: 'demo@devias.io',
          password: 'Password123!',
          submit: null
        }}
        validationSchema={Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required'),
            password: Yup
              .string()
              .max(255)
              .required('Password is required')
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signInWithEmailAndPassword(values.email, values.password);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Alert severity="info">
                <div>
                  Remember to sign in with your UMN account.
                  You can use
                  {' '}
                  <b>demo@devias.io</b>
                  {' '}
                  and password
                  {' '}
                  <b>Password123!</b>
                </div>
              </Alert>
            </Box>
          </form>
        )}
      </Formik> */}
    </div>
  );
};

export default LoginFirebase;
