/* eslint-disable */
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import useAuth from '../../hooks/useAuth';
// import BriefcaseIcon from '../../icons/Briefcase';
// import CalendarIcon from '../../icons/Calendar';
// import ChartPieIcon from '../../icons/ChartPie';
// import ChartSquareBarIcon from '../../icons/ChartSquareBar';
// import ChatAltIcon from '../../icons/ChatAlt';
// import ClipboardListIcon from '../../icons/ClipboardList';
// import FolderOpenIcon from '../../icons/FolderOpen';
// import MailIcon from '../../icons/Mail';
// import ShareIcon from '../../icons/Share';
// import ShoppingBagIcon from '../../icons/ShoppingBag';
// import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Dashboard from '@material-ui/icons/Dashboard';
import ManageSearchOutlined from '@material-ui/icons/ManageSearchOutlined';
import StarOutlined from '@material-ui/icons/StarOutlined';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import db from '../../firebaseDb';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
import AddBoxIcon from '@material-ui/icons/AddBox';

const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Guide',
        path: '/faculty/dashboard',
        icon: <Dashboard fontSize="small" />,
      },
    ],
  },
  {
    title: 'Lab',
    items: [
      {
        title: 'Lab Profile',
        path: '/faculty/dashboard/lab-profile',
        icon: <AccountBoxIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Listings',
    items: [
      {
        title: 'Create Listing',
        path: '/faculty/dashboard/listings/new',
        icon: <AddBoxIcon />,
        // children: [
        //   {
        //     title: 'Browse',
        //     path: '/dashboard/projects/browse'
        //   },
        // ]
      },
      {
        title: 'My Listings',
        path: '/faculty/dashboard/listings',
        icon: <AssignmentOutlined />,
        // children: [
        //   {
        //     title: 'Browse',
        //     path: '/dashboard/projects/browse'
        //   },
        // ]
      },
    ],
  },
  {
    title: 'Applications',
    items: [
      {
        title: 'Manage',
        path: '/faculty/dashboard/applications',
        icon: <PeopleIcon fontSize="small" />,
      },
    ],
  },
  //   {
  //     title: 'Student View',
  //     items: [
  //       {
  //         title: 'Browse',
  //         path: '/faculty/dashboard/browse',
  //         icon: <ManageSearchOutlined fontSize="small" />,
  //       },
  //     ]
  //   },
];

const FacultyDashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [userData, setUserData] = useState('');

  useEffect(() => {
    db.collection('users')
      .doc(user.id)
      .onSnapshot((doc) => {
        setUserData(doc.data());
      });
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/faculty/dashboard/lab-profile">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {userData.name}
              </Typography>
              <Typography color="textSecondary" noWrap variant="body2">
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/faculty/dashboard/lab-profile"
                >
                  {user.email}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        {/* // bottom:0, width:'100%', position:'sticky', backgroundColor:'#ffcc33' */}
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {/* Need Help? */}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {/* Check our docs   */}
          </Typography>
          <Button
            color="primary"
            // component={RouterLink}
            fullWidth
            sx={{ mt: 0 }}
            // to="https://gopheresearch.umn.edu/contact"
            variant="contained"
            onClick={() =>
              window.open('https://gopheresearch.umn.edu/contact', '_blank')
            }
          >
            Contact
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

FacultyDashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default FacultyDashboardSidebar;
